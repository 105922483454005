import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F30020",
    },
    error: {
      main: red[400],
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    fontFamily: "Noto Sans, sans-serif",
  },
});

export default theme;
