import styled from "styled-components";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import Snackbar from "@material-ui/core/Snackbar";

import QRCode from "qrcode.react";
import { isAndroid, isMobile } from "react-device-detect";
import { gpay, phonepe, paytm, whatsapp, upi } from "../../../assets/images";
import { useState } from "react";

const Container = styled.main`
  flex: 1;
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: ${isMobile ? "flex-start" : "center"};

  .price {
    font-size: 28pt;
    margin-bottom: 0.5rem;
  }

  .store {
    font-size: 13pt;
    span {
      font-weight: bold;
    }
  }

  .qr {
    width: 100%;
    height: auto;
    max-width: 300px;
    display: block;
    padding: 1rem;
    margin: 0 auto;
  }
  .upi {
    padding: 0 1rem ${isMobile ? "0.75rem" : "0"} 1rem;
    margin: 0 -1rem 1rem -1rem;
    border-bottom: ${isMobile ? "1px" : "0px"} solid #d9d9d9;
    user-select: none;

    div {
      display: flex;
      align-items: center;
      width: fit-content;
      margin: 0 auto;
      cursor: pointer;
      span {
        text-decoration: underline;
        margin-right: 0.5rem;
      }
    }
  }
  .modes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* width: 95%; */
    margin: 0.75rem auto 0.75rem auto;

    img {
      width: 56px;
      height: auto;
      border-radius: 16px;
      margin: 0 0.5rem 0.75rem 0.5rem;
      filter: drop-shadow(-3px -3px 3px #ffffff)
        drop-shadow(3px 3px 3px #d9d9d9);
      transition: filter 200ms ease;

      &:active {
        filter: none;
      }
    }
  }
  .note {
    color: #808080;
    text-align: left;
    font-size: 10pt;
    text-align: ${isMobile ? "left" : "center"};
  }
`;

const PaymentInfo = ({ deeplink, paymentDetails }) => {
  const [open, setOpen] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    setOpen(false);
  };
  const handleGpay = () => {
    //"phonepe" === e.data.payment_method ? r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=com.phonepe.app;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.phonepe.app&hl=en_IN;end" : "phonepe://{deeplink}" : "googlepay" === e.data.payment_method ? r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user;end" : "tez://upi/{deeplink}" : "paytmupi" === e.data.payment_method ? r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=net.one97.paytm;S.browser_fallback_url=https://play.google.com/store/apps/details?id=net.one97.paytm;end" : "paytmmp://upi/{deeplink}" : "whatsapp" === e.data.payment_method && (r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=com.whatsapp;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.whatsapp;end" : "whatsapp://{deepLink}"),
    if (isAndroid) {
      window.location.assign(
        `intent://${deeplink}#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user;end`
      );
    } else {
      window.location.assign(`tez://upi/${deeplink}`);
    }
  };

  const handlePhonepe = () => {
    window.location.assign(`phonepe://${deeplink}`);
  };

  const handlePaytm = () => {
    if (isAndroid) {
      window.location.assign(
        `intent://${deeplink}#Intent;scheme=upi;package=net.one97.paytm;S.browser_fallback_url=https://play.google.com/store/apps/details?id=net.one97.paytm;end`
      );
    } else {
      window.location.assign(`paytmmp://upi/${deeplink}`);
    }
  };

  const handleWhatsapp = () => {
    if (isAndroid) {
      window.location.assign(
        `intent://${deeplink}#Intent;scheme=upi;package=com.whatsapp;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.whatsapp;end`
      );
    } else {
      window.location.assign(`whatsapp://${deeplink}`);
    }
  };

  const handleGenericUpi = () => {
    window.location.assign(`upi://${deeplink}`);
  };

  return (
    <Container isMobile={isMobile}>
      <div className="price">
        {new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(paymentDetails.amount)}
      </div>
      <div className="store">
        Requested by <span>{paymentDetails.store_name}</span>
      </div>

      <QRCode
        className="qr"
        renderAs="svg"
        bgColor="#F7F7F7"
        value={`upi://${deeplink}`}
      />
      <div className="upi">
        <b>UPI ID</b>
        <div
          onClick={() => {
            navigator.clipboard.writeText(paymentDetails.user_vpa);
            setOpen(true);
          }}
        >
          <span>{paymentDetails.user_vpa}</span>{" "}
          <CopyIcon fontSize="small" style={{ color: "#242424" }} />
        </div>
      </div>
      {isMobile && (
        <>
          <div>Pay using</div>
          <div className="modes">
            <img src={gpay} onClick={handleGpay} />
            <img src={phonepe} onClick={handlePhonepe} />
            <img src={paytm} onClick={handlePaytm} />
            <img src={whatsapp} onClick={handleWhatsapp} />
            <img src={upi} onClick={handleGenericUpi} />
          </div>
        </>
      )}
      {isMobile ? (
        <div className="note">
          NOTE: If UPI apps are not opening then scan the QR Code from any UPI
          app directly.
        </div>
      ) : (
        <div className="note">
          NOTE: Use any UPI app and scan this QR Code to pay.
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="UPI ID copied to clipboard"
      />
    </Container>
  );
};

export default PaymentInfo;
